import { IKDataEntity } from 'metaflow-js'
import hillo from 'hillo'
import StringEntity from './StringEntity'
import GlobalSettings from '@/GlobalSettings'

const entity = {
  id: {
    type: IKDataEntity.Types.Integer,
    displayName: 'id',
    tableConfig: {
      align: 'start',
    },
    formConfig: {
      requiredNew: false,
    },
  },
  langs: {
    displayName: 'paymentname',
    type: IKDataEntity.Types.Group,
    formConfig: {
      groupName: 'paymentname',
      default: GlobalSettings.supportLang,
      childLabelKey: 'lang',
      type: {},
    },
    tableConfig: {
      displayCondition: (item) => {
        return item.lang.toUpperCase() === GlobalSettings.getLang().toUpperCase()
      },
    },
    childKey: 'name',
    children: [
      StringEntity, StringEntity, StringEntity,
    ],
  },
  fixedDisplay: {
    type: IKDataEntity.Types.Boolean,
  },
  isOnline: {
    displayName: 'isOnline',
    type: IKDataEntity.Types.Boolean,
  },
  apiKey: {
    required: false,
    formConfig: {
      requiredNew: false,
      requiredEdit: false,
    },
  },
}
const config = {
  add: function (item) {
    return hillo.post('PayMethod.php?op=add', {
      ...item,
      langs: JSON.stringify(item.langs),
    }, {
      showLoading: true,
    })
  },
  edit: function (item) {
    return hillo.postWithUploadFile('PayMethod.php?op=update', {
      ...item,
      langs: JSON.stringify(item.langs),
    }, {
      showLoading: true,
    })
  },
  remove: function (id) {
    return hillo.post('PayMethod.php?op=delete', { id: id })
  },
  load: async function (filter) {
    return (await hillo.get('PayMethod.php', {
      ...filter,
    })).content
  },
}

export default IKDataEntity.ModelFactory(entity, config)
